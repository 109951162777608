@font-face {
    font-family: 'Helvetica';
    font-weight: 400;
    src: url('../fonts/Helvetica-Regular.woff2') format('woff2'),
         url('../fonts/Helvetica-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica';
    font-weight: 700;
    src: url('../fonts/Helvetica-Bold.woff2') format('woff2'),
         url('../fonts/Helvetica-Bold.woff') format('woff');
}

@keyframes fadein {
    from { opacity: 0 }
    to { opacity: 1 }
}

.main-page {
    height: 100vh;
}

.main-page .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
}

.main-title {
    font-family: 'Helvetica', sans-serif;
    font-size: 27px;
    font-weight: 700;
    line-height: 1.48;
    text-align: center;
    text-transform: uppercase;
    color: #05b0ff;
    width: 100%;
    flex-shrink: 0;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 14px;
}

/*choose page*/
.choose-page {
    background-color: #f9f2f9;
    box-sizing: border-box;
    flex-grow: 1;
    padding-bottom: 30px;
    h2 {
        margin-bottom: 5px;
    }
    p {
        line-height: 1.38;
        text-align: center;
    }
    .btn-back {
        margin-bottom: 36px;
    }
}
.choose-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 440px;
    margin: 0 auto;
}
.choose-item-container {
    width: 33%;
    margin-bottom: 1px;
}
.choose-item {
    position: relative;
    padding-top: 100%;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}

/*collage page*/
.collage-page {
    h2 {
        margin-bottom: 8px;
        span {
            margin-bottom: 6px;
        }
    }
    p {
        line-height: 1.38;
        text-align: center;
        margin-bottom: 30px;
    }
    .btn-back {
        margin-bottom: 28px;
    }
    img {
        width: 100%;
    }
}
.share-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        background: none;
        margin: 0 15px;

        &.desktop-download {
            margin-top: 20px;
            color: #0f133c;
            font-weight: 700;
            text-transform: uppercase;
            max-width: 310px;
            width: 100%;
            height: 48px;
            border-radius: 100px;
            box-shadow: 1px 2px 2px 0 #2b3191;
            background-color: #ffc81e;
        }
    }
    svg {
        width: 100%;
    }
}

.collage-buttons-container {
    margin-top: 16px;

    button {
        display: block;
        max-width: 310px;
        width: 100%;
        margin: 8px auto 0;
        padding: 12px 10px 8px;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        border-radius: 6px;
        background-color: #0f133c;
    }
}

/*modal*/
.-show-popup {
    overflow: hidden;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.modal {
    max-width: 316px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
    img {
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-top: -40px;
    }
    h2 {
        line-height: 1.6;
        max-width: 220px;
        margin: 0 auto 8px;
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 24px;
    }
    li {
        font-size: 14px;
        font-weight: 600;
        color: #0f133c;
        background-color: #f1def2;
        border-radius: 20px;
        padding: 5px 8px;
        margin: 0 3px 6px;        
    }
    p {
        font-size: 14px;
        line-height: normal;
        color: #666;
        text-align: center;
        color: #030303;
        margin-bottom: 20px;
    }
    button {
        color: #0f133c;
        font-weight: bold;
        text-transform: uppercase;
        max-width: 194px;
        width: 100%;
        height: 42px;
        border-radius: 100px;
        box-shadow: 1px 2px 2px 0 #2b3191;
        background-color: #ffc81e;
        display: block;
        margin: 0 auto 20px;
    }
    .text-motive {
        line-height: normal;
        color: #333333;
        margin-bottom: 0;
        span {
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}

.btn-back {
    font-size: 15px;
    font-weight: bold;
    color: #e1aa00;
    display: flex;
    align-items: center;
    background-color: transparent;
    position: relative;
    top: -5px;
    left: -5px;
    padding: 5px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    margin-bottom: 9px;
}

.btn-back svg {
    width: 11px;
    margin-right: 10px;
}

/*index page*/
.index-page {
    button {
        position: absolute;
        left: 50%;
        bottom: 27px;
        transform: translateX(-50%);
        color: #0f133c;
        font-weight: 700;
        text-transform: uppercase;
        max-width: 310px;
        width: 100%;
        height: 48px;
        border-radius: 100px;
        box-shadow: 1px 2px 2px 0 #2b3191;
        background-color: #ffc81e;
        z-index: 30;
    }
    .bg-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .title-image-conatiner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        width: 100%;
        height: auto;
        background-image: linear-gradient(to bottom, rgba(38, 20, 98, 0), #261462 42%, #261462 51%, rgba(38, 20, 98, 0));
    }
    .title-image {
        max-width: 355px;
        max-height: 280px;
        object-fit: contain;
        width: 100%;
    }
}

@media (min-width: 769px) {
    .index-page {
        background: #0F133C;
        height: 100%;

        button {
            bottom: 25%;
        }
        .bg-image {
            object-fit: contain;
        }
        .title-image-conatiner {
            width: 80vh;
            height: 80vh;
            background-image: radial-gradient(circle at 50% 50%, #261462, rgba(38, 20, 98, 0) 71%);
        }
    }
}

/*error-page*/
.error-page {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
    }
    svg {
        max-width: 264px;
        margin-bottom: 5.5vh;
    }
    h3 {
        font-weight: bold;
        margin-bottom: 16px;
    }
    button {
        color: #0f133c;
        font-weight: 700;
        text-transform: uppercase;
        max-width: 310px;
        width: 100%;
        height: 48px;
        border-radius: 100px;
        box-shadow: 1px 2px 2px 0 #2b3191;
        background-color: #ffc81e;
        z-index: 30;
    }    
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    line-height: 1.38;
    width: 100%;
}

@media all and (max-width: 430px) and (max-height: 630px) {
    .collage-page img {
        width: 90%;
        margin: 0 auto;
        display: block;
    }
    .collage-page .btn-back {
        margin-bottom: 16px;
    }
    .collage-page p {
        margin-bottom: 16px;
    }
}
