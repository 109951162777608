html, body, .root {
    height: 100%;
}
.root > div {
    display: flex;
    flex-direction: column;
    height: 100%;
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.container {
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    padding: 14px 30px 27px;
    margin: 0 auto;
}

p {
    line-height: normal;
}

button {
    cursor: pointer;
    position: relative;
    padding: 0;
    input {
        opacity: 0;
        height: 0 !important;
        position: absolute;
        padding: 0 !important;
        margin: 0 !important;
    }
    &[disabled] {
        color: #999;
        background-color: #f3f3f3;
    }
}

a {
    text-decoration: none;
}

h2 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.13;
    text-align: center;
}

input {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

button {
    border: none;
}

/*loader*/
@keyframes spinner-1--before {
    0% {
        // transform: scale(1);
        opacity: .8;
    }

    50% {
        // transform: scale(3, 3);
        width: 300%;
        height: 300%;
        opacity: 0;
    }
    100% {
        width: 90%;
        height: 90%;
        opacity: 0;
    }
}

.spinonediv-1 {
    width: 140px;
    height: 140px;
    box-shadow: 0 0 40px 0 rgba(38, 20, 98, .6);
    // border: solid 2px #c77dff;
    border-radius: 50%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #f9f2f9;
}

.loader p {
    font-size: 18px;
    line-height: 1.33;
    text-align: center;
    color: #333;
    position: absolute;
    top: calc(45% + 220px);
    left: 50%;
    transform: translate(-50%,-50%);
    width: 75%;
}

.spinner-wave,
.spinner-wave2,
.spinner-wave3,
.spinner-wave4,
.loader-image-container {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.spinonediv-1:before,
.spinonediv-1:after,
.spinner-wave:before,
.spinner-wave:after,
.spinner-wave2:before,
.spinner-wave2:after,
.spinner-wave3:before,
.spinner-wave3:after,
.spinner-wave4:before,
.spinner-wave4:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid #261462;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    animation: spinner-1--before 12s linear infinite;
    // transform: scale(1);
    transform: translate(-50%,-50%);
    opacity: 0;
}

.spinonediv-1:after {
    animation-delay: 1.2s;
}

.spinner-wave:before {
    animation-delay: 2.4s;
}

.spinner-wave:after {
    animation-delay: 3.6s;
}

.spinner-wave2:after {
    animation-delay: 4.8s;
}

.spinner-wave2:before {
    animation-delay: 6s;
}

.spinner-wave3:after {
    animation-delay: 7.2s;
}

.spinner-wave3:before {
    animation-delay: 8.4s;
}

.spinner-wave4:after {
    animation-delay: 9.6s;
}

.spinner-wave4:before {
    animation-delay: 10.8s;
}


.spinner-wave2 {
    position: relative;
}

.loader-image-container {
    overflow: hidden;
    position: relative;
    z-index: 10;
}

.spinner-wave2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-image {
    position: absolute;
    top: 0;
    left: 0;
    border: solid 2px #261462;
    box-sizing: border-box;
    border-radius: 50%;
    animation-name: fadein;
    animation-duration: 320ms;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

/*slider*/
.slick-list,
.slick-slider {
    position: initial;
    display: block;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots {
    li {
        position: relative;
        display: inline-block;
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
    }
    button {
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
        color: transparent;
        border: 0;
        outline: none;
        background: #e0e0e0;
    }
    .slick-active {
        button {
            background: #000;
        }
    }
}

.slick-slide {
    float: left;
    font-size: 0;
}
